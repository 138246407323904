import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Sabel extends Component {
  static displayName = Sabel.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Sabel").map(pg => pg.id);

    return (
      <div>
        {pageIds.map((id) => (
          <ProductOverview key={id} Id={id} />
        ))}
      </div>
    );
  }
}
