import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Faegtebeklaedning extends Component {
  static displayName = Faegtebeklaedning.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Faegtebeklaedning").map(pg => pg.id);
    let pageId = Math.min(...pageIds);

    return (
      <div>
        <h3>Fægtebeklædning</h3>
        <h4>Husk at oplyse højre eller venstre for jakke, bukser, sikkerhedsvest og handske. </h4>
        <h4>
          Fægtedragt model &quot;ECOSTAR&quot;, 800N jævnfør FIE´s krav. (CE-godkendt).
        </h4>
        <h5>
          Til alle tre våben. Opfylder de nyeste FIE krav. Jakke og bukser holder mindst 800N over det hele. En ét lagsdragt i elastisk materiale som sikrer stor komfort. En rigtig god dragt til prisen. Har klingefang ved halsen og baglomme. Et fægtesæt, herre, i str. 50 vejer 2,3 kg.
        </h5>

        <ProductOverview Id={pageId++} />

        <h3>Fægtedragt model &quot;Startex&quot;, 800N jævnfør FIE´s krav. (CE-godkendt).</h3>
        <h4>Til
          alle tre våben. Dragten kan modstå mindst 800N over det hele og opfylder
          derved de nyeste FIE krav. Startex er lavet helt af det elastiske materiale <font className="font7">full-stretch</font>
          <font className="font5">, og er syet med kun ét lag stof. Derved opnås en<span> </span>
          </font>
          fantastisk bevægelsesfrihed samtidig med fuld beskyttelse. Klingefang ved halsen og baglomme. For kvinder med inderlomme til brystbeskyttere.
        </h4>

        <ProductOverview Id={pageId++} />

        <h3>Fægtedragt model &quot;ALPHA&quot;, 350N, <font className="font8">ikke</font>
          <font className="font6"><span> </span></font>
        </h3>
        <h4>Kun egnet til klubbrug. Ikke det rigtige valg, hvis man ønsker at deltage i konkurrencer. Model &quot;ECOSTAR&quot; er et bedre valg, fordi den er FIE godkendt.</h4>

        <ProductOverview Id={pageId++} />

        <h4>Husk at oplyse, hvis fægtetøjet skal passe til venstrehåndede fægtere.
          For de største størrelser, dvs. str. 60 og op tillægges prisen 10%.
          Fægtetøj kan specialsys til en merpris på min. 25% men kan så ikke byttes.
        </h4>

        <div style={{ width: '100%', textAlign: 'center' }}>
        <img src='./faegtebeklaedning/allstar_suits_en-fr-de-1.png' alt="GrTab_Anzug" />
        <img src='./faegtebeklaedning/allstar_suits_en-fr-de-2.png' alt="GrTab_Anzug" />
        </div>
      </div>
    );
  }
}
