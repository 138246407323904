import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Faegtesko extends Component {
  static displayName = Faegtesko.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Faegtesko").map(pg => pg.id);

    return (
      <div>
        {pageIds.map((id) => (
          <ProductOverview key={id} Id={id} />
        ))}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="./faegtesko/skostorrelser.png" alt="sko-storrelser" />
        </div>
      </div>
    );
  }
}
