import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'
import { Grid, Image } from 'semantic-ui-react';

export class TilbehoerKaarde extends Component {
  static displayName = TilbehoerKaarde.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "TilbehoerKaarde").map(pg => pg.id);

    return (
      <div>
      {pageIds.map((id) => (
        <ProductOverview key={id} Id={id} />
      ))}
        <Grid>
          <Grid.Row centered>
              <Image src='./kaarde-tilbehoer/accessories.png' alt="GrTab_Anzug" />
              <Image src='./kaarde-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div >
    );
  }
}
