import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';
import productGroups from '../data/productGroups.json'

export class Diverseogfaegtetasker extends Component {
  static displayName = Diverseogfaegtetasker.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Diverse").map(pg => pg.id);

    return (
      <div>
        {pageIds.map((id) => (
          <ProductOverview key={id} Id={id} />
        ))}
        <h5>
          Derududover er der mulighed for at få taskerne i nationalfarver og med navn, klubnavn eller landenavn påtrykt. Spørg i butikken.
        </h5>
        <Grid>
          <Grid.Row centered>
              <Image src='./diverse/ws4.png' alt="GrTab_Anzug" />
              <Image src='./diverse/wt-e.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
