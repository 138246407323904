import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'
import { Grid, Image } from 'semantic-ui-react';

export class Faegtepisteogtilbehoer extends Component {
  static displayName = Faegtepisteogtilbehoer.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Fægtepiste").map(pg => pg.id);

    return (
      <div>
      {pageIds.map((id) => (
        <ProductOverview key={id} Id={id} />
      ))}
        <Grid>
          <Grid.Row centered>
            <Image src='./faegtepiste/opruller.png' alt="GrTab_Anzug" />
            <Image src='./faegtepiste/oprullerdele.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./faegtepiste/piste.png' alt="GrTab_Anzug" />
            <Image src='./faegtepiste/spaende.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
