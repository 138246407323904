import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'
import { Grid, Image } from 'semantic-ui-react';

export class TilbehoerSabel extends Component {
  static displayName = TilbehoerSabel.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "TilbehoerSabel").map(pg => pg.id);

    return (
      <div>
      {pageIds.map((id) => (
        <ProductOverview key={id} Id={id} />
      ))}
        <Grid>
          <Grid.Row centered>
              <Image src='./sabel-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
