import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Sikkerhedstoej extends Component {
  static displayName = Sikkerhedstoej.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Sikkerhedstoej").map(pg => pg.id);

    return (
      <div>
        {pageIds.map((id) => (
          <ProductOverview key={id} Id={id} />
        ))}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="./sikkerhedstoej/storrelser.png" alt="GrTab_Plastron" />
        </div>
      </div>
    );
  }
}
