import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Fleuret extends Component {
  static displayName = Fleuret.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Fleuret").map(pg => pg.id);

    return (
      <div>
      {pageIds.map((id) => (
        <ProductOverview key={id} Id={id} />
      ))}
      </div>
    );
  }
}
