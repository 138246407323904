import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Masker extends Component {
  static displayName = Masker.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Masker").map(pg => pg.id);

    return (
      <div>
        {pageIds.map((id) => (
          <ProductOverview key={id} Id={id} />
        ))}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src='./masker/maskestorrelser.png' alt="GrTab_Anzug" />
        </div>
      </div>
    );
  }
}
