import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import productGroups from '../data/productGroups.json'

export class Handsker extends Component {
  static displayName = Handsker.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "Handsker").map(pg => pg.id);
    let pageId = Math.min(...pageIds);

    return (
      <div>
        <ProductOverview Id={pageId++} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src='./handsker/handkserstorrelser.png' alt="GrTab_Anzug" />
        </div>
      </div>
    );
  }
}
