import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';
import productGroups from '../data/productGroups.json'

export class TilbehoerFleuret extends Component {
  static displayName = TilbehoerFleuret.name;

  render() {
    const pageIds = productGroups.filter(pg => pg.page === "TilbehoerFleuret").map(pg => pg.id);

    return (
      <div>
      {pageIds.map((id) => (
        <ProductOverview key={id} Id={id} />
      ))}
        <Grid>
          <Grid.Row centered>
              <Image src='./fleuret-tilbehoer/accessories.png' alt="GrTab_Anzug" />
              <Image src='./fleuret-tilbehoer/cables.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./fleuret-tilbehoer/grips.png' alt="GrTab_Anzug" />
            <Image src='./fleuret-tilbehoer/guards.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./fleuret-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
